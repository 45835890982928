@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #b3b2b36e #f5f5f5a6;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 3px;
  height: 2px;
  overflow: visible;
}

*::-webkit-scrollbar-track {
  background: #f5f5f596;
}

*::-webkit-scrollbar-thumb {
  background-color: #b3b2b39a;
  border-radius: 7px;
  border: 0px solid #d4c4c4ad;
}

.MuiContainer-root {
  max-width: 1300px !important;
}

.changePasswordClass {
  display: none !important;
}

input:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}

.animated-bell {
  animation: bellAnimation 1s infinite;
}

@keyframes bellAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
    color: amber;
  }
  100% {
    transform: scale(1);
  }
}

#functionality-tooltip > .MuiTooltip-tooltip {
  margin-bottom: -3px;
}

#chooseFile-button-tooltip > .MuiTooltip-tooltip {
  margin-top: 24px;
  position: relative;
  right: 225px;
  width: 253px;
}

@media only screen and (max-width: 1339px) and (min-width: 900px) {
  #chooseFile-button-tooltip > .MuiTooltip-tooltip {
    right: 0px;
    left: 31px;
  }
}

.lineProgress {
  width: 48px;
}

@media only screen and (max-width: 600px) {
  .lineProgress {
    position: relative;
    right: 113px;
    top: 44px;
  }
}

/* Define a flicker animation with a light blue background color */
@keyframes flicker {
  0%,
  100% {
    background-color: transparent; /* Original background color */
  }
  50% {
    background-color: lightblue; /* Light blue background color */
  }
}

/* Apply the animation to a class */
.flicker {
  animation: flicker 2.5s ease-in-out;
}
